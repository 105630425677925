<template>
  <el-form
    size="small"
    ref="editForm"
    label-position="top"
    :model="scale"
    :rules="rules"
  >
    <el-form-item label="Шкала" prop="template_id">
      <el-select
        v-model="scale.template_id"
        @change="scaleSelectChanged"
      >
        <el-option
          v-for="template in scaleTemplates"
          :key="template.id"
          :label="template.name"
          :value="template.id"
        ></el-option>
      </el-select>
    </el-form-item>

    <el-form-item label="Единица измерения" prop="unit_id">
      <el-select
        v-model="scale.unit_id"
        filterable
      >
        <el-option
          v-for="unit in scalesUnits"
          :key="unit.id"
          :label="unit.symbol"
          :value="unit.id"
        ></el-option>
      </el-select>
    </el-form-item>


    <component
      :is="scaleScopesForm"
      :scale="scale"
      :disabled="!scaleScopesEditEnabled"
      @add-scope="addScope"
      @delete-scope="deleteScope"
    ></component>



    <component
      ref="elementScale"
      :is="scaleEditForm"
      :scale="scale"
    ></component>



  </el-form>
</template>

<script>

import {dynamicScaleComponent} from "@/mixins/dynamicScaleComponent";
import requestSender from "@/api/base/requestSender";

export default {
  name: "library-edit-form",
  mixins: [dynamicScaleComponent],
  components: {},

  props: {},

  computed: {
    scaleScopesEditEnabled() {
      const currentScale = this.scaleTemplates.find(t => t.id === this.scale.template_id);
      return currentScale && currentScale.allow_scopes_edit_in_element;
    },
  },

  watch: {
    'scale.template_id': function(newVal) {
      const template = this.scaleTemplates.find(t => t.id === newVal);
      const scaleType = template?.type;
      this.scaleEditForm = this.$companyConfiguration.scales.getConfigurableScaleTemplate(scaleType, 'libraryEditForm');
      this.scaleScopesForm = this.$companyConfiguration.scales.getConfigurableScaleSettingsTemplate(scaleType);
    }
  },

  mounted() {
    this.$nextTick(function () {
      this.$refs.editForm.clearValidate();
    });
    requestSender('get', 'scale/templates-list', {
      sort: 'name',
    })
      .then(data => {
        // У нас может быть удаленный или залоченный темплейт. Нужно его тоже пушнуть в массив
        if( this.scale.template ){
          if( !data.scales.find(t=>t.id===this.scale.template.id) ){
            data.scales.push({
              id: this.scale.template.id,
              name: this.scale.template.name,
            })
          }

          this.scaleEditForm = this.$companyConfiguration.scales.getConfigurableScaleTemplate(this.scale.template.type, 'libraryEditForm');
          this.scaleScopesForm = this.$companyConfiguration.scales.getConfigurableScaleSettingsTemplate(this.scale.template.type);
        }
        this.scaleTemplates = data.scales;

      })

    this.$api.library.getScalesUnits()
      .then(data => {
        // У нас может быть удаленная единица. Нужно ее тоже пушнуть в массив
        if( this.scale.unit ){
          if( !data.scales_units.find(t=>t.id===this.scale.unit.id) ){
            data.scales_units.push({
              id: this.scale.unit.id,
              symbol: this.scale.unit.symbol,
            })
          }
        }

        this.scalesUnits = data.scales_units;
      })

  },

  data() {
    return {
      scaleEditForm: null,
      scaleScopesForm: null,

      scalesUnits: [],
      scaleTemplates: [],

      scale:{
        template: null,
        unit: null,

        template_id: null,
        unit_id: null,

        scopes: [],

        plan: null,
        fact: null,
      },

      rules: {
        template_id: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
        unit_id: [{required: true, message: 'Поле обязательно для заполнения', trigger: 'change'}],
      }

    }
  },

  methods: {
    scaleSelectChanged(newVal){
      const template = this.scaleTemplates.find(t => t.id === newVal);

      this.scale.scopes = template ? template.scopes : [];
      if( template.allow_scopes_edit_in_element ){
        this.scale.scopes.forEach(scope => {
          scope.id = null;
        })
      }

    },
    addScope(){
      this.scale.scopes.push({});
    },
    deleteScope(scope){
      if( scope.id ){
        this.scale.scopes = this.scale.scopes.filter(s => s.id !== scope.id);
      } else {
        this.scale.scopes = this.scale.scopes.filter(s => (s.id || (!s.id && s.pay !== scope.pay)));
      }
    },
  }
}
</script>

<style>

</style>